<template>
  <div class="tcm zhong">
    <hd-com></hd-com>
    <div class="banner">
      <div class="banner-text">
        <h3>中医诊断</h3>
        <p>ZHONG YI ZHEN DUAN</p>
      </div>
    </div>
    <div class="main-div">
      <div class="center-div flex-space-between align-s">
        <div class="left-div">
          <div class="top-menu flex-start">
            <div class="item" :class="0 == TabCur ? 'zhongBg whiteC' : ''" @click="tabSelect(0)">四诊辨证</div>
            <div class="item" :class="1 == TabCur ? 'zhongBg whiteC' : ''" @click="tabSelect(1)">辨证方法</div>
          </div>
          <div class="" v-if="0 == TabCur">
            <div class="center-menu">
              <div class="item flex-space-between" :class="activeMenu == item.index ? 'activeItem' : ''" v-for="(item, index) in menuList" :key="index" @click="leftCheck(item.index)">
                <div class="flex-start">
                  <template v-if="activeMenu == item.index">
                    <img class="left-img" v-if="index == 0" src="../../assets/img/common/hp_wang02.png" />
                    <img class="left-img" v-if="index == 1" src="../../assets/img/common/hp_wei02.png" />
                    <img class="left-img" v-if="index == 2" src="../../assets/img/common/hp_wen02.png" />
                    <img class="left-img" v-if="index == 3" src="../../assets/img/common/hp_qie02.png" />
                  </template>
                  <template v-else>
                    <img class="left-img" v-if="index == 0" src="../../assets/img/common/hp_wang01.png" />
                    <img class="left-img" v-if="index == 1" src="../../assets/img/common/hp_wei01.png" />
                    <img class="left-img" v-if="index == 2" src="../../assets/img/common/hp_wen01.png" />
                    <img class="left-img" v-if="index == 3" src="../../assets/img/common/hp_qie01.png" />
                  </template>
                  <div class="flec-c-c">
                    <div class="t1 color3" :class="activeMenu == item.index ? 'activeT1' : ''">
                      {{ item.title }}
                    </div>
                    <div class="t2 color9" :class="activeMenu == item.index ? 'activeT2' : ''">
                      {{ item.desc }}
                    </div>
                  </div>
                </div>
                <i class="el-icon-arrow-right" :class="activeMenu == item.index ? 'zhongC' : 'color9'"></i>
              </div>
            </div>
            <div class="bottom-res">
              <div class="t1">已选症状<span class="t1-1">(可多选)</span>：</div>
              <div class="t2 zhongC">
                <span class="" v-for="(item1, a1) in TCMClassify" :key="a1" v-if="TCMClassify.length > 0">
                  <span class="" v-for="(item2, a2) in item1.childrens" :key="a2" v-if="item2.childrens.length > 0">
                    <span class="" v-for="(item3, a3) in item2.childrens" :key="a3" v-if="item3.childrens.length > 0">
                      <span class="" v-for="(item4, a4) in item3.childrens" :key="a4" v-if="item4.childrens.length > 0">
                        <span class="checkStr" v-for="(item5, a5) in item4.childrens" v-if="item5.isCheck">
                          {{ item5.text }}
                          <i class="el-icon-error delBtn zhongC" @click="delSelect(item5, a5)"></i>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div class="line"></div>
              <div class="res-desc">通过中医四诊望、闻、问、切.来观察患者的精神状态和一些相关事物, 以判断其健康状况</div>
              <div class="flex-end">
                <div class="btn1" @click="initData()">重置</div>
                <div class="btn2" @click="TCMSubmit()">四诊辨证</div>
              </div>
            </div>
          </div>
          <div class="" v-if="1 == TabCur">
            <div class="center-menu">
              <div class="item flex-space-between" :class="activeMenuDiff == index ? 'activeItem' : ''" v-for="(item, index) in differentClassify" :key="index" @click="leftCheck2(index)" style="height: 50px">
                <div class="t1 color3" :class="activeMenuDiff == index ? 'activeT1' : ''">
                  {{ item.categoryName }}
                </div>
                <i class="el-icon-arrow-right" :class="activeMenuDiff == index ? 'zhongC' : 'color9'"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="right-div">
          <div class="" v-if="0 == TabCur">
            <div class="top-t flex-start" v-for="(item, index1) in menuList" :key="index1" v-if="activeMenu == item.index">
              <div class="right-line"></div>
              <div class="t1">{{ item.title }}</div>
              <div class="t2">{{ item.desc }}</div>
            </div>
            <div class="top-line"></div>
            <div class="top-select flex-space-between">
              <el-select v-model="selectIndex1" placeholder="请选择" @change="change1" style="margin-right: 5px">
                <el-option v-for="item in TCMClassify[activeMenu].childrens" :key="item.categoryId" :label="item.categoryName" :value="item.categoryId"> </el-option>
              </el-select>
              <el-select v-model="selectIndex2" placeholder="请选择" @change="change2" style="margin-right: 5px">
                <el-option v-for="item in TCMClassify[activeMenu].childrens[index1].childrens" :key="item.categoryId" :label="item.categoryName" :value="item.categoryId"> </el-option>
              </el-select>
            </div>
            <div class="top-line2"></div>
            <div class="bottom-select flex-space-between">
              <div class="left-item flex-start-columns align-s">
                <div class="flex-center left-t" v-for="(item, i) in TCMClassify[activeMenu].childrens[index1].childrens[index2].childrens" :key="i">
                  <div class="left-mark" :class="{ leftActive: index3 == i }"></div>
                  <div @click="change3(i)">{{ item.categoryName }}</div>
                </div>
              </div>
              <div class="left-line"></div>
              <div class="right-item">
                <div class="flex-start flex-wrap align-c">
                  <el-tag :type="item.isCheck ? 'warning' : 'info'" v-for="(item, i1) in TCMClassify[activeMenu].childrens[index1].childrens[index2].childrens[index3].childrens" :key="i1" @click="change4(item, i1)" style="margin-right: 10px; margin-bottom: 10px">{{ item.categoryName }}</el-tag>
                </div>
              </div>
            </div>
          </div>
          <div class="" v-if="1 == TabCur">
            <div class="top-select flex-space-between">
              <div class="left-t">
                {{ differentClassify[activeMenuDiff].categoryName }}
              </div>
              <el-select v-model="selectDiffIndex1" placeholder="请选择" @change="changeDiff1">
                <el-option v-for="item in differentClassify[activeMenuDiff].childrens" :key="item.categoryId" :label="item.categoryName" :value="item.categoryId"> </el-option>
              </el-select>
            </div>
            <div class="top-line2"></div>
            <div class="bottom-select flex-space-between" style="height: 550px">
              <div class="left-item flex-start-columns align-s" style="height: 550px">
                <div class="flex-center left-t" v-for="(item, i) in differentClassify[activeMenuDiff].childrens[diffIndex1].childrens" :key="i">
                  <div class="left-mark" :class="{ leftActive: diffIndex2 == i }"></div>
                  <div @click="changeDiff2(i)">{{ item.categoryName }}</div>
                </div>
              </div>
              <div class="left-line" style="height: 548px"></div>
              <div class="right-item">
                <div class="flex-start flex-wrap align-c">
                  <el-tag :type="item.isCheck ? 'warning' : 'info'" v-for="(item, i1) in differentClassify[activeMenuDiff].childrens[diffIndex1].childrens[diffIndex2].childrens" :key="i1" @click="changeDiff3(item)" style="margin-right: 10px; margin-bottom: 10px">{{ item.categoryName }}</el-tag>
                </div>
                <div class="bottom-res" style="width: 77%">
                  <!-- <div class="top-line2"></div> -->
                  <div class="t2 zhongC">
                    <span class="" v-for="(item1, a1) in differentClassify" :key="a1" v-if="differentClassify.length > 0">
                      <span class="" v-for="(item2, a2) in item1.childrens" :key="a2" v-if="item2.childrens.length > 0">
                        <span class="" v-for="(item3, a3) in item2.childrens" :key="a3" v-if="item3.childrens.length > 0">
                          <span class="checkStr" v-for="(item4, a4) in item3.childrens" :key="a4" v-if="item4.isCheck">
                            {{ item4.text }}
                            <i class="el-icon-error zhongC delBtn" @click="delSelectDiff(item4, a4)"></i>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                  <div class="flex-end" style="width: 98%">
                    <div class="btn1" @click="orgData()">重置</div>
                    <div class="btn2" @click="getDiffDetail()">辨证方法</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ft-com></ft-com>
    <rt-bar></rt-bar>
    <UpgradeMsgBox ref="upgradeMsgBox" @currentlyAvailable="getCurrentlyAvailable" :fullPath="$router.currentRoute.fullPath"></UpgradeMsgBox>
  </div>
</template>

<script>
import hdCom from "../../components/header"; //引入组件
import ftCom from "../../components/footer"; //引入组件
// import rtBar from "../../components/right";
import { Differentiation } from "../../components/TCMDomain/Differentiation";
import { TCMDiagnosis } from "../../components/TCMDomain/TCMDiagnosis";
import { toHerbal } from "../../utils/relation";
import { DiseaseUtility, replaceContent, checkNameAndPath, toSearchList, toDetailInfo } from "../../utils/diseaseUtility";
import httpdata from "../../../public/services.json";
var http = httpdata;

export default {
  name: "tcmDiagnosis",
  components: {
    hdCom,
    ftCom,
    // rtBar
  },
  data() {
    var differentiation = new Differentiation(this.TokenClient, this.Services.TCM);
    var tcmDiagnosis = new TCMDiagnosis(this.TokenClient, this.Services.TCM);
    var diseaseUtility = new DiseaseUtility(this.TokenClient, this.Services);
    return {
      diseaseUtilityDomain: diseaseUtility,
      DifferentDomain: differentiation,
      TCMDomain: tcmDiagnosis,
      TabCur: 0,
      TCMClassify: [
        {
          childrens: [
            {
              childrens: [
                {
                  childrens: [
                    {
                      childrens: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
      prop: {
        text: "categoryName",
        value: "categoryId",
      },
      differentClassify: [
        {
          childrens: [],
        },
      ],
      errMsg: "",
      title: {
        look: [],
        ask: [],
        hear: [],
        puls: [],
        Diff: [],
      },
      menuList: [
        {
          title: "望诊",
          desc: "观气色，看舌象",
          activeImg: "hp_wang02.png",
          img: "hp_wang01.png",
          index: 0,
        },
        {
          title: "闻诊",
          desc: "听声音，嗅气味",
          activeImg: "hp_wei02.png",
          img: "hp_wei01.png",
          index: 1,
        },
        {
          title: "问诊",
          desc: "询问症状，了解病史",
          activeImg: "hp_wen02.png",
          img: "hp_wen01.png",
          index: 2,
        },
        {
          title: "切诊",
          desc: "切诊把脉，辨证施治",
          activeImg: "hp_qie02.png",
          img: "hp_qie01.png",
          index: 3,
        },
      ],
      activeMenu: 0,
      activeMenuDiff: 0,
      index1: 0,
      index2: 0,
      index3: 0,
      selectIndex1: 1,
      selectIndex2: 1,
      selectIdList: [],
      selectIdList1: [],
      selectIdList2: [],
      selectIdList3: [],
      selectIdList4: [],
      selectDiffIdList: [],
      diffIndex1: 0,
      diffIndex2: 0,
      selectDiffIndex1: 1,
      /*验证规则*/
      rules: {
        sorts: [{ required: true, message: "请选择分类", trigger: "change" }],
      },
      currentlyAvailable: false,
    };
  },
  mounted() {
    this.$bus.$emit("isShowChangeSize");
    this.getTCM();
  },
  methods: {
    getCurrentlyAvailable(data) {
      this.currentlyAvailable = data;
    },
    leftCheck2(index) {
      this.activeMenuDiff = index;
      this.diffIndex1 = 0;
      this.diffIndex2 = 0;
      this.selectDiffIndex1 = this.differentClassify[this.activeMenuDiff].childrens[this.diffIndex1].value;
      this.selectDiffIdList = [];
      this.orgData();
    },
    changeDiff1(e) {
      if (e && e >= 0) {
        this.diffIndex1 = this.getIndex(this.differentClassify[this.activeMenuDiff].childrens, e);
        this.diffIndex2 = 0;
      }
    },
    changeDiff2(e) {
      this.diffIndex2 = e;
    },
    changeDiff3(item) {
      if (item.isCheck == 1) {
        item.isCheck = 0;
        this.selectDiffIdList.map((org, i) => {
          if (org.value == item.value) {
            this.selectDiffIdList.splice(i, 1);
          }
        });
      } else {
        item.isCheck = 1;
        let map = {
          value: item.value,
          text: item.text,
          nameStr: `${this.differentClassify[this.activeMenuDiff].categoryName}-${this.differentClassify[this.activeMenuDiff].childrens[this.diffIndex1].categoryName}-${this.differentClassify[this.activeMenuDiff].childrens[this.diffIndex1].childrens[this.diffIndex2].categoryName}-${item.text}`,
        };
        this.selectDiffIdList.push(map);
      }
    },
    leftCheck(index) {
      this.activeMenu = index;
      this.index1 = 0;
      this.index2 = 0;
      this.index3 = 0;
      this.selectIndex1 = this.TCMClassify[this.activeMenu].childrens[this.index1].value;
      this.selectIndex2 = this.TCMClassify[this.activeMenu].childrens[this.index1].childrens[this.index2].value;
      this.getSelectData();
    },
    getIndex(item, value) {
      return item.map((i) => i.value).indexOf(value);
    },
    getSelectData() {
      switch (this.activeMenu) {
        case 0:
          this.selectIdList = this.selectIdList1;
          break;
        case 1:
          this.selectIdList = this.selectIdList2;
          break;
        case 2:
          this.selectIdList = this.selectIdList3;
          break;
        case 3:
          this.selectIdList = this.selectIdList4;
          break;
      }
    },
    change1(e) {
      if (e && e >= 0) {
        this.index1 = this.getIndex(this.TCMClassify[this.activeMenu].childrens, e);
        this.index2 = 0;
        this.index3 = 0;
        this.selectIndex2 = this.TCMClassify[this.activeMenu].childrens[this.index1].childrens[0].value;
      }
      this.getSelectData();
    },
    change2(e) {
      if (e && e >= 0) {
        this.index2 = this.getIndex(this.TCMClassify[this.activeMenu].childrens[this.index1].childrens, e);
        this.index3 = 0;
      }
      this.getSelectData();
    },
    change3(e) {
      this.index3 = e;
      this.getSelectData();
    },
    change4(item, index) {
      if (item.isCheck == 1) {
        item.isCheck = 0;
        this.selectIdList.map((org, i) => {
          if (org.value == item.value) {
            this.selectIdList.splice(i, 1);
          }
        });
      } else {
        item.isCheck = 1;
        let map = {
          value: item.value,
          text: item.text,
          nameStr: `${this.TCMClassify[this.activeMenu].childrens[this.index1].categoryName}-${this.TCMClassify[this.activeMenu].childrens[this.index1].childrens[this.index2].categoryName}-${this.TCMClassify[this.activeMenu].childrens[this.index1].childrens[this.index2].childrens[this.index3].categoryName}-${item.text}`,
        };
        this.selectIdList.push(map);
      }
      switch (this.activeMenu) {
        case 0:
          this.selectIdList1 = this.selectIdList;
          this.selectIdList1 = Array.from(new Set(this.selectIdList1.map(JSON.stringify))).map(JSON.parse);
          console.log(this.selectIdList1);
          break;
        case 1:
          this.selectIdList2 = this.selectIdList;
          this.selectIdList2 = Array.from(new Set(this.selectIdList2.map(JSON.stringify))).map(JSON.parse);

          console.log(this.selectIdList2);
          break;
        case 2:
          this.selectIdList3 = this.selectIdList;
          this.selectIdList3 = Array.from(new Set(this.selectIdList3.map(JSON.stringify))).map(JSON.parse);
          break;
        case 3:
          this.selectIdList4 = this.selectIdList;
          this.selectIdList4 = Array.from(new Set(this.selectIdList4.map(JSON.stringify))).map(JSON.parse);
          break;
      }
    },
    delSelect(item, index) {
      item.isCheck = 0;
      let i = this.getIndex(this.selectIdList1, item.value);
      if (this.getIndex(this.selectIdList1, item.value) >= 0) {
        this.selectIdList1.splice(i, 1);
      }
      if (this.getIndex(this.selectIdList2, item.value) >= 0) {
        this.selectIdList2.splice(i, 1);
      }
      if (this.getIndex(this.selectIdList3, item.value) >= 0) {
        this.selectIdList3.splice(i, 1);
      }
      if (this.getIndex(this.selectIdList4, item.value) >= 0) {
        this.selectIdList4.splice(i, 1);
      }
    },
    delSelectDiff(item, index) {
      item.isCheck = 0;
      let i = this.getIndex(this.selectDiffIdList, item.value);
      if (this.getIndex(this.selectDiffIdList, item.value) >= 0) {
        this.selectDiffIdList.splice(i, 1);
      }
    },
    tabSelect(e) {
      this.TabCur = e;
      this.scrollLeft = (e - 1) * 60;
    },
    //分类获取
    getTCM: function () {
      var _this = this;
      _this.TCMDomain.TCMDiagnosis(
        function (data) {
          _this.TCMClassify = data.data;
          _this.initData();
          _this.getDifferent();
        },
        function (error) {
          console.log("分类获取失败!请刷新重试。", error);
        }
      );
    },
    initData() {
      this.selectIdList = [];
      this.selectIdList1 = [];
      this.selectIdList2 = [];
      this.selectIdList3 = [];
      this.selectIdList4 = [];
      const mapTree = (item) => {
        const haveChildren = Array.isArray(item.childrens) && item.childrens.length > 0;
        return {
          ...item,
          text: item.categoryName,
          value: item.categoryId,
          isCheck: 0,
          childrens: haveChildren ? item.childrens.map((child) => mapTree(child)) : null,
        };
      };
      this.TCMClassify = this.TCMClassify.map((item) => mapTree(item));
      this.index1 = 0;
      this.index2 = 0;
      this.index3 = 0;
      this.selectIndex1 = this.TCMClassify[this.activeMenu].childrens[this.index1].value;
      this.selectIndex2 = this.TCMClassify[this.activeMenu].childrens[this.index1].childrens[this.index2].value;
    },
    TCMSubmit: function () {
      if (!this.currentlyAvailable) {
        this.$refs.upgradeMsgBox.openTip();
        return
      }
      var lookstr = "";
      this.selectIdList1
        .filter((x) => x.hasOwnProperty("value"))
        .forEach(function (c) {
          lookstr += c.value + ",";
        });

      var hearstr = "";
      this.selectIdList2
        .filter((x) => x.hasOwnProperty("value"))
        .forEach(function (c) {
          hearstr += c.value + ",";
        });

      var askstr = "";
      this.selectIdList3
        .filter((x) => x.hasOwnProperty("value"))
        .forEach(function (c) {
          askstr += c.value + ",";
        });

      var pulsestr = "";
      this.selectIdList4
        .filter((x) => x.hasOwnProperty("value"))
        .forEach(function (c) {
          pulsestr += c.value + ",";
        });

      lookstr = lookstr == "" ? 0 : lookstr;
      hearstr = hearstr == "" ? 0 : hearstr;
      askstr = askstr == "" ? 0 : askstr;
      pulsestr = pulsestr == "" ? 0 : pulsestr;

      if (lookstr == 0 && hearstr == 0 && askstr == 0 && pulsestr == 0) {
        this.$message({
          center: true,
          type: "warning",
          message: "请选择望闻问切症状类型！",
        });
        return false;
      }
      let params = `${lookstr}/${hearstr}/${askstr}/${pulsestr}`;
      this.title.look = this.selectIdList1;
      this.title.hear = this.selectIdList2;
      this.title.ask = this.selectIdList3;
      this.title.puls = this.selectIdList4;
      var titleName = JSON.stringify(this.title);
      this.$router.push({
        path: "./tcmDetail",
        query: {
          params: params,
          class: "tcm",
          title: titleName,
        },
      });
    },
    getDiffDetail: function () {
      if (!this.currentlyAvailable) {
        this.$refs.upgradeMsgBox.openTip();
        return
      }
      var diffstr = "";
      if (this.selectDiffIdList.length == 0) {
        this.$message({
          center: true,
          type: "warning",
          message: "请选择辨证方法！！",
        });
        return false;
      }
      this.selectDiffIdList.forEach(function (c) {
        diffstr += c.value + ",";
      });
      this.SelDiffCategoryId = diffstr;
      this.SelDiffCategoryId = this.SelDiffCategoryId == "" ? 0 : this.SelDiffCategoryId;
      let params = `${this.SelDiffCategoryId}`;
      console.log(this.selectDiffIdList);
      this.title.Diff = this.selectDiffIdList;
      var titleName = JSON.stringify(this.title);
      this.$router.push({
        path: "./tcmDetail",
        query: {
          params: params,
          class: "diff",
          title: titleName,
        },
      });
    },
    //辨证方法分类获取
    getDifferent: function () {
      var _this = this;
      _this.DifferentDomain.Differentiation(
        function (data) {
          _this.differentClassify = data.data;
          _this.orgData();
        },
        function (error) {
          console.log("分类获取失败!请刷新重试。", error);
        }
      );
    },
    orgData() {
      this.selectDiffIdList = [];
      const mapTree = (item) => {
        const haveChildren = Array.isArray(item.childrens) && item.childrens.length > 0;
        return {
          ...item,
          text: item.categoryName,
          value: item.categoryId,
          isCheck: 0,
          childrens: haveChildren ? item.childrens.map((child) => mapTree(child)) : null,
        };
      };
      this.differentClassify = this.differentClassify.map((item) => mapTree(item));
      this.diffIndex1 = 0;
      this.diffIndex2 = 0;
      this.selectDiffIndex1 = this.differentClassify[this.activeMenuDiff].childrens[this.diffIndex1].value;
    },
  },
};
</script>

<style scoped lang="scss">
.tcm {
  background: #f1f1f1;
}

.zhong .banner {
  height: 116px;
  background: url("../../assets/img/jbk_banner_zy.jpg") center no-repeat;
}

.banner-text {
  width: 1200px;
  margin: 0 auto;
}

.banner-text h3 {
  padding-top: 25px;
  font-size: 26px;
  font-weight: normal;
  color: #ffffff;
}

.banner-text p {
  font-size: 18px;
  color: #ffffff;
}

.main-div {
  width: 1200px;
  margin: 40px auto 70px;
  border-radius: 8px;

  .center-div {
    .left-div {
      width: 40%;
      padding: 20px;
      height: 600px;
      overflow-y: auto;
      background-color: #fff;
      border-radius: 8px;
      margin-right: 10px;

      .top-menu {
        width: 240px;
        height: 33px;
        font-size: 16px;
        font-weight: 400;
        color: #e89f3a;
        border: 1px solid #eca23d;
        border-radius: 5px;
        margin: 10px 0 25px;

        .item {
          width: 50%;
          height: 33px;
          line-height: 33px;
          text-align: center;
          border-radius: 5px;
          cursor: pointer;
        }
      }

      .center-menu {
        .item {
          height: 58px;
          background: #f2f2f2;
          box-shadow: 0px 1px 10px 0px rgba(186, 186, 186, 0.05);
          border-radius: 8px;
          padding: 0 10px;
          margin-bottom: 15px;
          cursor: pointer;

          .left-img {
            width: 40px;
            height: 40px;
            margin-right: 5px;
          }

          .t1 {
            font-size: 16px;
            font-weight: bold;

            line-height: 20px;
            line-height: 22px;
          }

          .activeT1 {
            color: #ed9d2f;
          }

          .t2 {
            font-size: 13px;
            font-weight: 400;
            line-height: 27px;
            line-height: 22px;
          }

          .activeT2 {
            color: #eaad5a;
          }

          .icon-you_right {
            font-size: 20px;
          }
        }

        .activeItem {
          background: #fffaf2;
          border: 1px solid #ee9822;
        }
      }

      .bottom-res {
        margin: 30px 0;

        .t1 {
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          line-height: 24px;
        }

        .t1-1 {
          font-size: 12px;
        }

        .t2 {
          margin: 8px 0;

          .delBtn {
            color: #fff;
            border-radius: 50%;
            font-size: 14px;
            height: 12px;
            line-height: 12px;
            padding: 2px 1.5px 1px 1.5px;
            cursor: pointer;
          }
        }

        .line {
          height: 1px;
          font-size: 12px;
          font-weight: 300;
          border-bottom: 1px dashed #fbb24e;
          line-height: 24px;
          margin: 20px 0;
        }

        .res-desc {
          font-size: 11px;
          font-weight: 400;
          color: #cccccc;
          margin-bottom: 10px;
        }

        .btn1 {
          width: 90px;
          height: 30px;
          line-height: 30px;
          background: #ffffff;
          border: 1px solid #eca23d;
          border-radius: 13px;
          font-size: 12px;
          font-weight: 400;
          color: #ed9d2f;
          text-align: center;
          margin-right: 10px;
          cursor: pointer;
        }

        .btn2 {
          width: 90px;
          height: 30px;
          line-height: 30px;
          background: #eca23d;
          border-radius: 13px;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
        }

        .t2 {
          .checkStr {
            margin-right: 10px;
            word-break: break-word;
          }
        }
      }
    }

    .right-div {
      width: 59%;
      padding: 15px 0 0 0;
      height: 625px;
      position: relative;
      background-color: #fff;
      border-radius: 8px;

      .top-t {
        height: 45px;

        .right-line {
          width: 5px;
          height: 20px;
          background: #eca23d;
        }

        .t1 {
          font-size: 20px;
          font-weight: bold;
          color: #333333;
          line-height: 24px;
          margin: 0 10px;
        }

        .t2 {
          font-size: 15px;
          font-weight: 400;
          color: #999999;
          line-height: 27px;
        }
      }

      .top-line {
        width: 100%;
        height: 4px;
        background: #f1f1f1;
        border-radius: 2px;
        margin: 5px 0;
      }

      .top-line2 {
        width: 100%;
        height: 1px;
        background: #cccccc;
        border-radius: 2px;
        margin: 5px 0 0;
      }

      .top-select {
        padding: 15px 20px;

        ::v-deep .el-select {
          width: 49%;

          .el-input__inner {
            border: none;
            background: #f1f1f1;
          }
        }

        ::v-deep .uni-stat-box {
          background: #f1f1f1;
        }

        ::v-deep .uni-select {
          border: none;
        }

        .left-t {
          margin-right: 5px;
          width: 49%;
          text-align: center;
          background-color: #f1f1f1;
          height: 35px;
          line-height: 35px;
          cursor: pointer;
        }
      }

      .bottom-select {
        height: 485px;

        .left-line {
          width: 1px;
          background-color: #cccccc;
          height: 488px;
        }

        .left-item {
          width: 22%;
          height: 488px;
          overflow-y: auto;

          .left-t {
            width: 80%;
            margin: 0 auto 10px;
            cursor: pointer;
          }

          .left-t:first-child {
            margin-top: 20px;
          }

          // border-right: 1px solid #cccccc;
          .left-mark {
            width: 4px;
            height: 13px;
            border-radius: 2px;
            margin-right: 20px;
          }

          .leftActive {
            background: #eca23d;
          }

          ::v-deep .uni-section .uni-section-header__decoration {
            background-color: #eca23d;
          }

          ::v-deep .uni-section .uni-section-header {
            padding: 10px;
          }

          ::v-deep .uni-section__content-title {
            font-weight: bold;
          }

          ::v-deep .left-title .uni-section__content-title {
            font-weight: 400;
          }

          ::v-deep .left-title .uni-section-header__content {
            margin-left: 10px;
          }

          ::v-deep .uni-section-header {
            text-align: center;
          }
        }

        .right-item {
          width: 77%;
          margin: 20px 15px 10px;
          height: calc(100% - 30px);
          overflow-y: auto;

          .el-tag {
            cursor: pointer;
            font-size: 14px;
            padding: 0px 20px;
          }

          ::v-deep .uni-data-checklist .checklist-group .checklist-box.is--tag.is-checked {
            background: #fffaf2 !important;
            border: 1px solid #ee9822 !important;
          }

          ::v-deep .uni-data-checklist .checklist-group .checklist-box.is--tag.is-checked .checklist-text {
            color: #ed9d2f !important;
          }

          ::v-deep .uni-data-checklist .checklist-group .checklist-box.is--tag {
            background: #f2f2f2 !important;
          }
        }
      }

      ::v-deep .right-box .uni-section .uni-section-header__decoration {
        background-color: #eca23d;
      }

      .right-box {
        height: calc(100vh - 110px - 60px);
        overflow-y: auto;
      }

      .bottom-res {
        position: absolute;
        right: 0;
        bottom: 25px;
        box-shadow: 0px -3px 5px 0px rgba(230, 230, 230, 0.5);
        padding-top: 10px;

        .t2 {
          margin: 5px 10px 0;
        }

        .btn1 {
          width: 90px;
          height: 30px;
          line-height: 30px;
          background: #ffffff;
          border: 1px solid #eca23d;
          border-radius: 13px;
          font-size: 12px;
          font-weight: 400;
          color: #ed9d2f;
          text-align: center;
          margin-right: 10px;
          cursor: pointer;
        }

        .delBtn {
          color: #fff;
          border-radius: 50%;
          font-size: 14px;
          height: 12px;
          line-height: 12px;
          padding: 2px 1.5px 1px 1.5px;
          cursor: pointer;
        }

        .checkStr {
          margin-right: 10px;
          word-break: break-word;
        }

        .btn2 {
          width: 90px;
          height: 30px;
          line-height: 30px;
          background: #eca23d;
          border-radius: 13px;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }
}

.text-zy {
  color: #b76c12 !important;
}

/* 辩证方法tab */
.subTab-top {
  position: relative;
}

.subTab-top scroll-view {
  float: left;
  width: calc(100% - 100rpx);
}

.subTab-top .more-btn {
  float: right;
  width: 90rpx;
  line-height: 90rpx;
  text-align: center;
}

.tab-list {
  position: absolute;
  right: 20rpx;
  top: 90rpx;
  /* width: 200rpx; */
  background-color: #f5f5f5;
  z-index: 99;
}

.tab-list .tab-item {
  padding: 20rpx 20rpx;
  text-align: center;
  color: #666666;
  border-bottom: 1px solid #eeeeee;
}

/*  */
.tab-wrap .uni-row {
  margin-bottom: 20rpx;
}

.tab-wrap .uni-row .item-label {
  width: 100rpx;
  text-align: center;
  line-height: 80rpx;
}

.item-from {
  margin-left: 100rpx;
  overflow: hidden;
}

.item-from .input-item {
  overflow: hidden;
}

.item-add {
  width: 100rpx;
  margin-left: 80rpx;
}

.flex-item .sm-btn {
  margin-top: 15rpx;
}

.sm-btn {
  display: inline-block;
  margin-left: 20rpx;
  line-height: 1.5;
}

.tcmBtn {
  display: inline-block;
  padding: 20rpx;
  font-size: 28rpx;
  color: #ffffff;
  background-color: #b76c12;
  padding: 0 30rpx;
  font-size: 28rpx;
  height: 64rpx;
  line-height: 64rpx;
}

/* picker选择器样式覆盖 */
.selected-item-active {
  border-bottom: 2px solid #b76c12 !important;
}

.zhongC {
  color: #e89f3a !important;
}

.zhongBg {
  background-color: #e89f3a !important;
}

.zhongBorder {
  border: 1px solid #e89f3a;
}

.xiC {
  color: #04afa9 !important;
}

.xiBorder {
  border: 1px solid #04afa9;
}

.xiBg {
  background-color: #04afa9 !important;
}

.whiteC {
  color: #fff;
}
</style>
